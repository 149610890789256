<ng-container *ngIf="showCompletedOption">
  <mat-slide-toggle
    [(ngModel)]="includeCompleted"
    (change)="toggleIncludeComplete()"
  >
    Show Completed?
  </mat-slide-toggle>
</ng-container>

<button
  mat-raised-button
  routerLink="/checklistdesign/create"
  style="float: right; margin-bottom: 10px;"
  [hidden]="hideCreate"
>
  <i class="material-icons-outlined">add</i>&nbsp;
  <span>Create a New Checklist</span>
</button>

<table mat-table [dataSource]="checklists$ | async" class="mat-elevation-z8">
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let checklist">
      <!-- Depending on checklists status and user's role then different links are shown -->
      <a
        [routerLink]="
          viewOnlyLink
            ? '/checklist/' + checklist.id
            : getChecklistLinkAction(checklist).link
        "
        [matTooltip]="
          isOverdue(checklist)
            ? viewOnlyLink
              ? 'Overdue! View Checklist'
              : 'Overdue! ' + getChecklistLinkAction(checklist).tooltip
            : viewOnlyLink
            ? 'View Checklist'
            : getChecklistLinkAction(checklist).tooltip
        "
        [style.color]="isOverdue(checklist) ? 'red' : 'black'"
        >{{ checklist.name }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let checklist">
      <mat-icon
        [matTooltip]="helper.getChecklistStatusInfoItem(checklist.status).name"
        >{{
          helper.getChecklistStatusInfoItem(checklist.status).icon
        }}</mat-icon
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let checklist">
      {{
        checklist.description.length < 100
          ? checklist.description
          : checklist.description.substring(0, 99) + "..."
      }}
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="team">
    <th mat-header-cell *matHeaderCellDef>Team</th>
    <td mat-cell *matCellDef="let checklist">
      {{ checklist.team.name }}
    </td>
  </ng-container> -->

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef [hidden]="!showDelete"></th>
    <td mat-cell *matCellDef="let checklist" [hidden]="!showDelete">
      <div
        (click)="deleteChecklist(checklist)"
        matTooltip="Delete Checklist"
        class="delete_checklist"
        [id]="'D' + checklist.name"
      >
        <mat-icon>clear</mat-icon>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
