<div
  [hidden]="
    !checklistitem.requireEvidence ||
    !redirectForEvidence ||
    (checklistitem.evidence && checklistitem.evidence.length > 0)
  "
>
  <a
    [routerLink]="
      '/checklist/' + cid + '/checklistitemedit/' + checklistitem.id
    "
    matTooltip="Evidence is required, so must be entered in the checklist item details"
  >
    <mat-icon>subdirectory_arrow_right</mat-icon></a
  >
</div>
<div
  [style.color]="checklistitem.resultValue == undefined ? 'lightgrey' : 'black'"
  [hidden]="
    checklistitem.requireEvidence &&
    redirectForEvidence &&
    (!checklistitem.evidence || checklistitem.evidence.length == 0)
  "
>
  <ng-container
    *ngIf="
      checklistitem.resultType == ChecklistitemResultType.checkbox &&
      !checklistitem.allowNA
    "
    class="result"
  >
    <mat-checkbox
      [ngModel]="checklistitem.resultValue"
      (change)="onChange($event, 'YesNo')"
      [disabled]="disabled"
      [matTooltip]="
        'Select Yes or No' +
        (checklistitem.resultValue == undefined ? ' : No value set!' : '')
      "
      >Y/N</mat-checkbox
    >
  </ng-container>

  <ng-container
    *ngIf="
      checklistitem.resultType == ChecklistitemResultType.checkbox &&
      checklistitem.allowNA
    "
    class="result"
  >
    <mat-checkbox
      [checked]="checklistitem.resultValue == ChecklistitemResultValue.true"
      (change)="onChange($event, 'YesNo')"
      [disabled]="disabled"
      [matTooltip]="
        'Select Yes or No' +
        (checklistitem.resultValue == undefined ? ' : No value set!' : '')
      "
      >Y/N</mat-checkbox
    >
    <mat-checkbox
      [checked]="checklistitem.resultValue == ChecklistitemResultValue.NA"
      (change)="onChange($event, 'NA')"
      [disabled]="disabled"
      style="margin-left: 5px;"
      matTooltip="or select 'N/A' if the item is Not Applicable"
      >N/A</mat-checkbox
    >
  </ng-container>

  <ng-container
    *ngIf="
      checklistitem.resultType == ChecklistitemResultType.rating ||
      checklistitem.resultType == ChecklistitemResultType.ratingNA
    "
    class="result"
  >
    <mat-select
      [ngModel]="checklistitem.resultValue"
      (selectionChange)="onChange($event, 'Rating')"
      [disabled]="disabled"
      [matTooltip]="
        'Select a rating' +
        (checklistitem.resultValue == undefined ? ' : No value set!' : '')
      "
    >
      <mat-option
        [value]="ChecklistitemResultValue.NA"
        [hidden]="!checklistitem.allowNA"
      >
        N/A
      </mat-option>
      <mat-option [value]="ChecklistitemResultValue.low">
        1. Low
      </mat-option>
      <mat-option [value]="ChecklistitemResultValue.mediumLow">
        2. Medium/Low
      </mat-option>
      <mat-option [value]="ChecklistitemResultValue.medium">
        3. Medium
      </mat-option>
      <mat-option [value]="ChecklistitemResultValue.mediumHigh">
        4. Medium/High
      </mat-option>
      <mat-option [value]="ChecklistitemResultValue.high">
        5. High
      </mat-option>
    </mat-select>
  </ng-container>
</div>
