<app-subheading
  prompt="My Checklists"
  matIcon="playlist_add_check"
></app-subheading>
<div class="page-container">
  <app-checklistlist
    [checklists$]="checklists$"
    [showCompletedOption]="true"
    (showCompletedChange)="showCompletedChange($event)"
  ></app-checklistlist>
</div>
