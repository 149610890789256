<app-subheading
  prompt="Create Checklist from Template"
  [iconActions]="[
    {
      icon: 'close',
      toolTip: 'Close',
      emitValue: 'close'
    }
  ]"
  (iconAction)="iconAction($event)"
></app-subheading>
<br />
<form class="standard-form" [formGroup]="checklistForm">
  <fieldset>
    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="name"
        placeholder="Name"
        required
        id="checklistName"
      />
      <mat-error *ngIf="checklistForm.controls['name'].hasError('required')">
        Name is required.
      </mat-error>
      <mat-error *ngIf="checklistForm.controls['name'].hasError('minlength')">
        Name must be at least 3 characters long.
      </mat-error>
      <mat-error *ngIf="checklistForm.controls['name'].hasError('maxlength')">
        Name must be no more than 70 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea
        matInput
        formControlName="description"
        matAutosizeMinRows="3"
        matAutosizeMaxRows="10"
        matTextareaAutosize
        placeholder="Description"
        id="checklistDescription"
        required
      ></textarea>
      <mat-error
        *ngIf="checklistForm.controls['description'].hasError('required')"
      >
        Description is required.
      </mat-error>
      <mat-error
        *ngIf="checklistForm.controls['description'].hasError('minlength')"
      >
        Description must be at least 10 characters long.
      </mat-error>
      <mat-error
        *ngIf="checklistForm.controls['description'].hasError('maxlength')"
      >
        Description must be no more than 500 characters long.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Team</mat-label>
      <mat-select
        [value]="checklist.team"
        (selectionChange)="onTeamChange($event)"
        [compareWith]="objectComparisonFunction"
        class="full-width"
        id="checklistTeam"
        #team
      >
        <mat-option [value]="-1">
          None
        </mat-option>
        <mat-option
          *ngFor="let team of myteams$ | async"
          [value]="helper.docRef('/teams/' + team.id)"
        >
          {{ team.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </fieldset>
</form>
<i
  >Note: The name should be updated before creating a new checklist, otherwise
  checklists with the same name could be created. Also check that the correct
  team is selected. Incorrect team assignments can only be updated by an
  administrator after the checklist has been created.</i
><br />

<br />
<button
  mat-raised-button
  matTooltip="Create Checklist"
  (click)="ok()"
  class="create-button"
  id="createChecklist"
  [disabled]="!checklistForm.valid || team.empty"
>
  <mat-icon>check</mat-icon>Create Checklist
</button>
