export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDmnveqIVBjd8ba9JQuhMK5QTC80MB1mfk",
    authDomain: "ourchecklists.firebaseapp.com",
    databaseURL: "https://ourchecklists.firebaseio.com",
    projectId: "ourchecklists",
    storageBucket: "ourchecklists.appspot.com",
    messagingSenderId: "153514111017",
    appId: "1:153514111017:web:f472005cd72d67ebb2ec38",
    measurementId: "G-XBCZPJBBZS"
  }
};
