<app-subheading prompt="My Teams" matIcon="supervisor_account"></app-subheading>
<div class="page-container">
  <ng-container *ngIf="canCreateTeams">
    <button
      mat-raised-button
      routerLink="/team/create"
      style="float: right; margin-bottom: 10px;"
    >
      <i class="material-icons-outlined">supervisor_account</i>&nbsp;
      <span>Create a New Team</span>
    </button>
    <br />
  </ng-container>

  <table mat-table [dataSource]="teams$ | async" class="mat-elevation-z8">
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let team">
        <a routerLink="/team/{{ team.id }}">{{ team.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let team">
        {{
          team.description.length < 100
            ? team.description
            : team.description.substring(0, 99) + "..."
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let team">
        {{ getRole(team) }}
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="Checklists">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let team">
        <button
          mat-button
          [routerLink]="['/teamchecklists/', team.id]"
          matTooltip="Team Checklists"
        >
          <mat-icon>playlist_add_check</mat-icon>
        </button>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="deleteRow" *matCellDef="let team">
        <button
          mat-button
          [routerLink]="['/team/delete/', team.id]"
          matTooltip="Delete Team"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
