<button
  mat-raised-button
  style="float: right; margin-bottom: 10px;"
  [hidden]="hideCreate"
  (click)="addResource()"
>
  <i class="material-icons-outlined">add</i>&nbsp;
  <span>Add Resource </span>
</button>
<br />

<table mat-table [dataSource]="resources" class="mat-elevation-z8">
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td
      mat-cell
      *matCellDef="let resource"
      [style.opacity]="
        (resource | doc | async)?.status == ResourceStatus.superseded
          ? '0.5'
          : '1'
      "
    >
      <div (click)="openResourceView(resource)" title="View Resource">
        {{ (resource | doc | async)?.name }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td
      mat-cell
      *matCellDef="let resource"
      [style.opacity]="
        (resource | doc | async)?.status == ResourceStatus.superseded
          ? '0.5'
          : '1'
      "
    >
      {{
        (resource | doc | async)?.description.length < 100
          ? (resource | doc | async)?.description
          : (resource | doc | async)?.description.substring(0, 99) + "..."
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resourceType">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td
      mat-cell
      *matCellDef="let resource"
      [style.opacity]="
        (resource | doc | async)?.status == ResourceStatus.superseded
          ? '0.5'
          : '1'
      "
    >
      <img
        src="./../../assets/icons/{{
          getResourceTypeInfoItem((resource | doc | async)?.resourceType)?.icon
        }}"
        matTooltip="{{
          getResourceTypeInfoItem((resource | doc | async)?.resourceType)?.name
        }}"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="deleteRow" *matCellDef="let resource">
      <button
        mat-button
        (click)="removeResource(resource)"
        matTooltip="Remove Resource"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
