<!-- Image carousel showing applications main functions -->

<ngb-carousel>
  <ng-template ngbSlide class="carousel-item carousel-image-1 border-10">
    <picture>
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/home-1-1200x500.jpg"
      />
      <source
        media="(min-width: 465px)"
        srcset="../../assets/images/home-1-400x300.jpg"
      />
      <img
        src="../../assets/images/home-1-400x300.jpg"
        alt="Checklist"
        width="100%"
      />
    </picture>
    <div class="carousel-caption">
      <h4><i class="far fa-list-alt"></i> Checklists</h4>
      <p>Streamline tasks with checklists.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide class="carousel-item carousel-image-2 border-10">
    <picture>
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/home-2-1200x500.jpg"
      />
      <source
        media="(min-width: 465px)"
        srcset="../../assets/images/home-2-400x300.jpg"
      />
      <img
        src="../../assets/images/home-2-400x300.jpg"
        alt="Checklist"
        width="100%"
      />
    </picture>
    <div class="carousel-caption">
      <h4><i class="far fa-list-alt"></i> Community</h4>
      <p>Share checklists and results.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide class="carousel-item carousel-image-1 border-10">
    <picture>
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/home-3-1200x500.jpg"
      />
      <source
        media="(min-width: 465px)"
        srcset="../../assets/images/home-3-400x300.jpg"
      />
      <img
        src="../../assets/images/home-3-400x300.jpg"
        alt="Checklist"
        width="100%"
      />
    </picture>
    <div class="carousel-caption">
      <h4><i class="far fa-list-alt"></i> Analyze</h4>
      <p>Review, analyze and improve.</p>
    </div>
  </ng-template>
</ngb-carousel>

<!-- Cards with more information about application functions (align with carousel info) -->

<div class="card-container">
  <mat-card class="home-card">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="card-1-image card-image"></div>
      <mat-card-title>Checklists</mat-card-title>
      <mat-card-subtitle>Streamline tasks with checklists.</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p class="card-text text-justify">
        Use checklists to streamline your activities, tasks and processes for
        business, sports and hobbies. Move paper based checklists to more
        convenient electronic forms (Include supporting material, trainings and
        media). Include photos and videos with completed checklists. Do checks
        with better quality and more easily from your mobile device.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="home-card">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="card-2-image card-image"></div>
      <mat-card-title>Teams</mat-card-title>
      <mat-card-subtitle>Share checklists with your teams.</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p class="card-text text-justify">
        Publish checklists to your teams. Use the teams to improve, share and
        refine checklists. Strong controls included, over who has access to your
        checklists, and who defines your teams.
      </p>
      <p class="card-text">
        Real time sharing of checklists, multiple people can work on the same
        checklist at the same time
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="home-card">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="card-3-image card-image"></div>
      <mat-card-title>Analyze</mat-card-title>
      <mat-card-subtitle>Analyze the results of checklists.</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p class="card-text text-justify">
        Perform analysis and reporting on checklists, either as individual
        checklists, or as groups of checklists. Analyze trends, score results,
        roll into metrics.
      </p>
    </mat-card-content>
  </mat-card>
</div>
