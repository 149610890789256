<button
  mat-raised-button
  style="float: right; margin-bottom: 10px;"
  [hidden]="hideCreate"
  (click)="addUser()"
>
  <i class="material-icons-outlined">add</i>&nbsp;
  <span>Add User</span>
</button>
<br />

<table mat-table [dataSource]="users" class="mat-elevation-z8">
  <ng-container matColumnDef="displayName" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let user">
      {{ (user | doc | async)?.displayName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="uid">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="deleteRow" *matCellDef="let user">
      <button mat-button (click)="removeUser(user)" matTooltip="Remove User">
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
