<mat-spinner *ngIf="showSpinner" class="spinner_container"></mat-spinner>
<app-subheading
  prompt="Administration Utilities"
  matIcon="construction"
></app-subheading>
<div class="page-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Clean up Deleted Checklists</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Clean up deleted checklists and checklist items. Read through logically
        deleted checklists, if one is found, then batch delete the checklist
        items sub-collection and then the checklist.
      </p>
      <button
        mat-button-outline
        (click)="checklistCleanup()"
        id="checklistCleanup"
      >
        Go
      </button>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-header>
      <mat-card-title>Clean up Orphaned Resources</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Clean up orphan resources on checklists and checklistitems. Read each
        checklist/checklistitem resource and check the docref is valid,
        otherwise remove the resource.
      </p>
      <button
        mat-button-outline
        (click)="resourceCleanup()"
        id="resourceCleanup"
      >
        Go
      </button>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-header>
      <mat-card-title>Category & Team Cleanup</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Go through checklists and resources and remove any category or team
        document references that no longer exist.
      </p>
      <button mat-button-outline (click)="ctCleanup()" id="ctCleanup">
        Go
      </button>
    </mat-card-content>
  </mat-card>
  <div *ngIf="deletedChecklists?.length > 0">
    <hr />
    Checklists deleted [{{ deletedChecklists.length }} rows]
    <ol>
      <li *ngFor="let checklist of deletedChecklists">
        {{ checklist.id }} - {{ checklist.name }}
      </li>
    </ol>
  </div>

  <div *ngIf="removedResources?.length > 0">
    <hr />
    Checklists Resources cleaned up [{{ removedResources.length }} rows]
    <ol>
      <li *ngFor="let resource of removedResources">
        {{ resource.path }}
      </li>
    </ol>
  </div>
  <div *ngIf="removedItemResources?.length > 0">
    <hr />
    Checklist item Resources cleaned up [{{ removedItemResources.length }} rows]
    <ol>
      <li *ngFor="let resource of removedItemResources">
        {{ resource.path }}
      </li>
    </ol>
  </div>
  <div *ngIf="categoryCleanup?.length > 0">
    <hr />
    Orphan categories removed [{{ categoryCleanup.length }} rows]
    <ol>
      <li *ngFor="let ref of categoryCleanup">
        {{ ref.path }}
      </li>
    </ol>
  </div>
  <div *ngIf="teamCleanup?.length > 0">
    <hr />
    Orphan teams removed [{{ teamCleanup.length }} rows]
    <ol>
      <li *ngFor="let ref of teamCleanup">
        {{ ref.path }}
      </li>
    </ol>
  </div>
</div>
