<button
  mat-raised-button
  style="float: right; margin-bottom: 10px;"
  [hidden]="hideCreate"
  (click)="addActivity()"
>
  <i class="material-icons-outlined">add</i>&nbsp;
  <span>Add Activity</span>
</button>
<br />

<table mat-table [dataSource]="activities" class="mat-elevation-z8">
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let activity">
      {{ (activity | doc | async)?.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="deleteRow" *matCellDef="let activity">
      <button
        mat-button
        (click)="removeActivity(activity)"
        matTooltip="Remove Activity"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
