<app-subheading
  prompt="Checklist Search
  "
  matIcon="search"
></app-subheading>

<div class="page-container min-page">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Search
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-datasearch
      (checklistObservable)="showChecklists($event)"
      [makeExtract]="false"
      [includeTeamMembers]="true"
    ></app-datasearch>
  </mat-expansion-panel>
  <br />
  <app-checklistlist
    [checklists$]="checklists$"
    [hideCreate]="true"
    [viewOnlyLink]="true"
    [showDelete]="false"
  ></app-checklistlist>
</div>
