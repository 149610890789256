<table mat-table [dataSource]="checklists$ | async" class="mat-elevation-z8">
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let checklist">
      <!-- Depending on checklists status and user's role then different links are shown -->
      <a
        [routerLink]="'/template/' + checklist.id"
        matTooltip="View Template Details"
        >{{ checklist.name }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let checklist">
      {{
        checklist.description.length < 100
          ? checklist.description
          : checklist.description.substring(0, 99) + "..."
      }}
    </td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th
      mat-header-cell
      *matHeaderCellDef
      [hidden]="
        !(auth.currentUser.isAdmin || auth.currentUser.isTemplateManager)
      "
    ></th>
    <td
      mat-cell
      *matCellDef="let checklist"
      matTooltip="Delete Template"
      [hidden]="
        !(auth.currentUser.isAdmin || auth.currentUser.isTemplateManager)
      "
    >
      <mat-icon (click)="deleteTemplate(checklist)" class="delete_template"
        >clear</mat-icon
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
