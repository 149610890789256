<app-subheading prompt="Resource List" matIcon="folder_open"></app-subheading>
<div class="page-container">
  <div class="filter-container">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filters
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table>
          <tr height="40px">
            <td>Name</td>
            <td>
              <input type="text" (keyup)="onNameKey($event)" />
            </td>
          </tr>
          <tr>
            <td>Optional Filter</td>
            <td>
              <mat-radio-group
                class="filterType-radio-group"
                (change)="getResourceList()"
                [(ngModel)]="filterType"
              >
                <mat-radio-button class="filterType-radio-button" value="Team">
                  Team
                </mat-radio-button>

                <mat-radio-button
                  class="filterType-radio-button"
                  value="Category"
                >
                  Category
                </mat-radio-button>

                <mat-radio-button class="filterType-radio-button" value="Owner">
                  Owner
                </mat-radio-button>

                <mat-radio-button
                  class="filterType-radio-button"
                  value="Reviewer"
                >
                  Reviewer
                </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <mat-form-field [hidden]="filterType != 'Team'">
                <mat-select
                  [(ngModel)]="teamFilter"
                  (selectionChange)="filterType = 'Team'; getResourceList()"
                >
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option
                    *ngFor="let team of teams$ | async"
                    [value]="helper.docRef('teams/' + team.id)"
                  >
                    {{ team.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [hidden]="filterType != 'Category'">
                <mat-select
                  [(ngModel)]="categoryFilter"
                  (selectionChange)="filterType = 'Category'; getResourceList()"
                >
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option
                    *ngFor="let category of categories$ | async"
                    [value]="helper.docRef('categories/' + category.id)"
                  >
                    {{ category.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [hidden]="filterType != 'Owner'">
                <mat-select
                  [(ngModel)]="ownerFilter"
                  (selectionChange)="filterType = 'Owner'; getResourceList()"
                >
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option
                    *ngFor="let user of users$ | async"
                    [value]="helper.docRef('users/' + user.uid)"
                  >
                    {{ user.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [hidden]="filterType != 'Reviewer'">
                <mat-select
                  [(ngModel)]="reviewerFilter"
                  (selectionChange)="filterType = 'Reviewer'; getResourceList()"
                >
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option
                    *ngFor="let user of users$ | async"
                    [value]="helper.docRef('users/' + user.uid)"
                  >
                    {{ user.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
        <div>
          <mat-checkbox [(ngModel)]="showSuperseded" (click)="getResourceList()"
            >Show Superseded?</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="showUrl"
            (click)="getResourceList()"
            class="cb-selector"
            >Show URLs?</mat-checkbox
          >

          <mat-checkbox
            [(ngModel)]="showYouTube"
            (click)="getResourceList()"
            class="cb-selector"
            >Show YouTube?</mat-checkbox
          >
          <mat-checkbox
            [(ngModel)]="showFile"
            (click)="getResourceList()"
            class="cb-selector"
            >Show File?</mat-checkbox
          >
          <mat-checkbox
            [(ngModel)]="showMarkdown"
            (click)="getResourceList()"
            class="cb-selector"
            >Show Markdown?</mat-checkbox
          >
          <mat-checkbox
            [(ngModel)]="showImage"
            (click)="getResourceList()"
            class="cb-selector"
            >Show Image?</mat-checkbox
          >
        </div>
      </mat-expansion-panel></mat-accordion
    >
  </div>
  <button
    mat-raised-button
    routerLink="/resource/create"
    style="float: right; margin-bottom: 5px;"
  >
    <i class="material-icons-outlined">add</i>&nbsp;
    <span>Create a New Resource</span>
  </button>

  <table mat-table [dataSource]="resources$ | async" class="mat-elevation-z8">
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td
        mat-cell
        *matCellDef="let resource"
        [style.opacity]="
          resource.status == ResourceStatus.superseded ? '0.5' : '1'
        "
      >
        <a routerLink="/resource/{{ resource.id }}">{{ resource.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td
        mat-cell
        *matCellDef="let resource"
        [style.opacity]="
          resource.status == ResourceStatus.superseded ? '0.5' : '1'
        "
      >
        {{
          resource.description.length < 100
            ? resource.description
            : resource.description.substring(0, 99) + "..."
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="resourceType">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td
        mat-cell
        *matCellDef="let resource"
        [style.opacity]="
          resource.status == ResourceStatus.superseded ? '0.5' : '1'
        "
      >
        <button mat-button (click)="popupResource(resource)">
          <img
            src="./../../assets/icons/{{
              getResourceTypeInfoItem(resource.resourceType).icon
            }}"
            matTooltip="{{
              getResourceTypeInfoItem(resource.resourceType).name
            }} - Click to view"
          />
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        class="deleteRow"
        *matCellDef="let resource"
        [style.opacity]="
          resource.status == ResourceStatus.superseded ? '0.5' : '1'
        "
      >
        <button
          mat-button
          [routerLink]="['/resource/delete/', resource.id]"
          matTooltip="Delete Resource"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
