<mat-form-field class="full-width">
  <input
    matInput
    (keyup)="onKey($event)"
    placeholder="Search for Name"
    autocomplete="off"
  />
</mat-form-field>

<div class="user-list">
  <div
    *ngFor="let user of users$ | async"
    class="list-item"
    [style.backgroundColor]="
      user.uid == helper.getDocRefId(selectedUser) ? 'lightgrey' : 'white'
    "
    (click)="onUserSelected(helper.docRef('users/' + user.uid))"
    [hidden]="isHidden(helper.docRef('users/' + user.uid))"
  >
    {{ user.displayName }}
  </div>
</div>
