<mat-spinner
  *ngIf="
    categorySpinner ||
    teamSpinner ||
    activitySpinner ||
    userSpinner ||
    checklistSpinner
  "
  class="spinner_container"
></mat-spinner>
<div class="grid50percent">
  <!-- Status -->
  <div>
    <mat-form-field class="full-width">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="selectedStatus" class="full-width">
        <mat-option [value]="'0'">All</mat-option>
        <mat-option
          *ngFor="let statusInfo of checklistStatusInfo"
          [value]="statusInfo.status.toString()"
        >
          {{ statusInfo.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Category -->
  <div>
    <mat-form-field class="full-width">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="selectedCategory" class="full-width">
        <mat-option [value]="'0'">All</mat-option>
        <mat-option *ngFor="let category of categoryInfo" [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Team -->
  <div>
    <mat-form-field class="full-width">
      <mat-label>Team</mat-label>
      <mat-select [(ngModel)]="selectedTeam" class="full-width">
        <mat-option [value]="'0'" *ngIf="user?.isAdmin">All</mat-option>
        <mat-option [value]="'-1'">None</mat-option>
        <ng-container *ngFor="let team of teamInfo">
          <mat-option
            [value]="team.id"
            *ngIf="
              user?.isAdmin ||
              user.managerOfTeams?.includes(team.id) ||
              user.reviewerOfTeams?.includes(team.id) ||
              (includeTeamMembers && user.memberOfTeams?.includes(team.id))
            "
          >
            {{ team.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <div class="fake-label">Template</div>
    <span
      (click)="templateDialog()"
      id="templateDialog"
      style="cursor: pointer; display: inline-block; width: 100%;"
      matTooltip="Select Template"
      >{{ selectedTemplate.name }}</span
    >

    <mat-divider></mat-divider>
  </div>
</div>

<!-- Template -->

<div class="grid50percent">
  <!-- From-date -->
  <div>
    <mat-form-field appearance="standard" class="full-width">
      <mat-label>From Completion Date</mat-label>
      <input matInput [matDatepicker]="fromDt" [(ngModel)]="selectedFromDate" />
      <mat-datepicker-toggle matSuffix [for]="fromDt"></mat-datepicker-toggle>
      <mat-datepicker #fromDt></mat-datepicker>
    </mat-form-field>
  </div>
  <!-- To-date -->
  <div>
    <mat-form-field appearance="standard" class="full-width">
      <mat-label>To Completion Date</mat-label>
      <input matInput [matDatepicker]="toDt" [(ngModel)]="selectedToDate" />
      <mat-datepicker-toggle matSuffix [for]="toDt"></mat-datepicker-toggle>
      <mat-datepicker #toDt></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<div>
  <ng-container *ngIf="downLoadReady">
    <a
      [href]="fileUrlJSON"
      [download]="extractNameJSON"
      [matTooltip]="
        'Click to download: ' + rows + ' checklists and items as JSON'
      "
      class="social-distancing"
      >[Checklists & Items]
    </a>
    <a
      [href]="fileUrlChecklists"
      [download]="extractNameChecklists"
      [matTooltip]="
        'Click to download: ' + checklistRows + ' checklists as CSV'
      "
      class="social-distancing"
      >[Checklists]
    </a>
    <a
      [href]="fileUrlChecklistitems"
      [download]="extractNameChecklistitems"
      [matTooltip]="
        'Click to download: ' + checklistitemRows + ' checklistitems as CSV'
      "
      class="social-distancing"
      >[Checklistitems]
    </a>
  </ng-container>
  <button
    mat-button-outline
    (click)="getSelectedData()"
    class="search-button"
    id="search"
  >
    Search
  </button>
</div>
