<button
  mat-raised-button
  [routerLink]="'/checklist/' + checklist.id + '/checklistitemdesign/create'"
  style="float: right; margin-bottom: 10px;"
  [hidden]="hideCreate"
>
  <i class="material-icons-outlined">add</i>&nbsp;
  <span>Create a New Checklist Item</span>
</button>
<br />

<table
  mat-table
  [dataSource]="checklistitems$ | async"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let checklistitem; let i = index">
      <!-- Depending on checklists status and user's role then different links are shown -->

      <a
        [routerLink]="getChecklistitemLinkAction(checklistitem).link"
        [matTooltip]="getChecklistitemLinkAction(checklistitem).tooltip"
        >{{ i + 1 }}. {{ checklistitem.name }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="deleteRow" *matCellDef="let checklistitem">
      <button
        mat-button
        [routerLink]="
          '/checklist/' +
          checklist.id +
          '/checklistitemdesign/' +
          checklistitem.id +
          '/delete'
        "
        matTooltip="Delete Checklist Item"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
