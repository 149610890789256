<div class="dialog-heading">Select Resource</div>
<mat-card>
  <mat-form-field class="full-width">
    <mat-label>Filter by Name</mat-label>
    <input matInput (keyup)="onKey($event)" autocomplete="off" />
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Filter by Category</mat-label>
    <mat-select (selectionChange)="onCategoryChange($event.value)" value="All">
      <mat-option value="All">All</mat-option>
      <mat-option
        *ngFor="let category of categories$ | async"
        [value]="category.id"
        >{{ category.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</mat-card>

<div class="resource-list">
  <div
    *ngFor="let resource of resources$ | async"
    class="list-item"
    [style.backgroundColor]="
      resource.id == selectedResource?.id ? 'lightgrey' : 'white'
    "
    (click)="onResourceSelected(resource)"
    [hidden]="isHidden(resource)"
    [matTooltip]="
      ((resource?.category | doc | async)?.name
        ? (resource?.category | doc | async)?.name + ': '
        : '') + shortDescription(resource)
    "
  >
    {{ resource.name }}
  </div>
</div>

<button
  mat-button
  matTooltip="Add Resource"
  (click)="returnResource()"
  [disabled]="!selectedResource"
  class="action-button"
>
  <mat-icon>check</mat-icon>OK
</button>

<button class="action-button" mat-button matTooltip="Cancel" mat-dialog-close>
  <mat-icon>close</mat-icon>Cancel
</button>
