<div class="dialog-heading">Select Team</div>

<mat-form-field class="full-width">
  <input
    matInput
    (keyup)="onKey($event)"
    placeholder="Search for Team"
    autocomplete="off"
  />
</mat-form-field>

<div class="team-list">
  <div
    class="list-item"
    *ngFor="let t of teams$ | async"
    [style.backgroundColor]="team?.id == t.id ? 'lightgrey' : 'white'"
    (click)="onTeamSelected(t.id)"
  >
    {{ t.name }}
  </div>
</div>

<button
  mat-button
  matTooltip="Update Team Information"
  (click)="returnTeam()"
  [disabled]="!team"
>
  <mat-icon>check</mat-icon>OK
</button>

<button mat-button matTooltip="Cancel" mat-dialog-close>
  <mat-icon>close</mat-icon>Cancel
</button>
