<app-subheading
  prompt="Checklist Status"
  [iconActions]="[
    {
      icon: 'close',
      toolTip: 'Close',
      emitValue: 'close'
    }
  ]"
  (iconAction)="iconAction($event)"
></app-subheading>
<div class="prompt">Current Status</div>
<div class="current-status">
  {{ helper.getChecklistStatusInfoItem(checklist.status).name }}
  <div class="status-description">
    {{ helper.getChecklistStatusInfoItem(checklist.status).name }}
    :
    {{ helper.getChecklistStatusInfoItem(checklist.status).description }}
  </div>
</div>
<div class="prompt">You can change the Status to...</div>
<div>
  <mat-radio-group [(ngModel)]="newStatus">
    <ng-container
      *ngIf="
        checklist.status == ChecklistStatus.Active && !checklist.fromTemplate
      "
    >
      <mat-radio-button
        [value]="ChecklistStatus.UnderConstruction"
        [id]="'rbstatus' + ChecklistStatus.UnderConstruction"
      >
        Under Construction
      </mat-radio-button>
      <div class="status-description current-status">
        {{
          helper.getChecklistStatusInfoItem(ChecklistStatus.UnderConstruction)
            .name
        }}:
        {{
          helper.getChecklistStatusInfoItem(ChecklistStatus.UnderConstruction)
            .description
        }}
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        checklist.status == ChecklistStatus.UnderConstruction ||
        checklist.status == ChecklistStatus.Done
      "
    >
      <mat-radio-button
        [value]="ChecklistStatus.Active"
        [id]="'rbstatus' + ChecklistStatus.Active"
      >
        Active
      </mat-radio-button>
      <div class="status-description current-status">
        {{ helper.getChecklistStatusInfoItem(ChecklistStatus.Active).name }}:
        {{
          helper.getChecklistStatusInfoItem(ChecklistStatus.Active).description
        }}
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        checklist.status == ChecklistStatus.Active ||
        checklist.status == ChecklistStatus.Complete
      "
    >
      <mat-radio-button
        [value]="ChecklistStatus.Done"
        [id]="'rbstatus' + ChecklistStatus.Done"
      >
        Done
      </mat-radio-button>
      <div class="status-description current-status">
        {{ helper.getChecklistStatusInfoItem(ChecklistStatus.Done).name }}:
        {{
          helper.getChecklistStatusInfoItem(ChecklistStatus.Done).description
        }}
      </div>
    </ng-container>

    <ng-container *ngIf="checklist.status == ChecklistStatus.Done">
      <mat-radio-button
        [value]="ChecklistStatus.Complete"
        [id]="'rbstatus' + ChecklistStatus.Complete"
      >
        Complete
      </mat-radio-button>
      <div class="status-description current-status">
        {{ helper.getChecklistStatusInfoItem(ChecklistStatus.Complete).name }}:
        {{
          helper.getChecklistStatusInfoItem(ChecklistStatus.Complete)
            .description
        }}
      </div>
    </ng-container>
  </mat-radio-group>
</div>
<br />
<button
  mat-raised-button
  matTooltip="Update Status"
  (click)="updateStatus()"
  class="update-button"
  [disabled]="!newStatus"
  id="ok"
>
  <mat-icon>check</mat-icon>OK
</button>
