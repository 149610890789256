<!-- <ng-container *ngIf="showAction">
  <button mat-raised-button matTooltip="Update Status" (click)="statusDialog()">
    {{ helper.getChecklistStatusInfoItem(checklist.status).name }}
  </button>
</ng-container> -->
<ng-container *ngIf="!showAction">
  {{ helper.getChecklistStatusInfoItem(checklist.status).name }}
</ng-container>
<ng-container *ngIf="showAction">
  <div (click)="statusDialog()" id="statusDialog" class="status">
    <span matTooltip="Click to Update Status"
      >[{{ helper.getChecklistStatusInfoItem(checklist?.status).name }}]</span
    >
  </div>
</ng-container>
