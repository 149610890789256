<mat-spinner *ngIf="showSpinner" class="spinner_container"></mat-spinner>
<div class="colgrid2">
  <div>
    <div class="file_item" *ngFor="let file of files" style="font-size: small;">
      <a
        [href]="file.url$ | async"
        target="_blank"
        [matTooltip]="'Open: ' + file.name"
        >{{
          file.name.length < 60 ? file.name : file.name.substring(0, 60) + "..."
        }}</a
      ><mat-icon
        class="delete-icon"
        (click)="onDeleteFile(file.name)"
        [matTooltip]="'Delete: ' + file.name"
        [hidden]="readOnly"
        >clear</mat-icon
      >
    </div>
  </div>
  <div>
    <button
      class="add-button"
      style="
        font-size: small;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 7px;
      "
      mat-stroked-button
      (click)="fileUpload.click()"
      [hidden]="readOnly"
    >
      Add&nbsp;New&nbsp;Evidence&nbsp;File
    </button>

    <input
      type="file"
      #fileUpload
      hidden="true"
      (change)="onUploadFile($event)"
      multiple="false"
    />
  </div>
</div>
