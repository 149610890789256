<div class="card-container">
  <mat-card class="about-card">
    <mat-card-header class="card-header">
      <mat-card-title>About</mat-card-title>
      <mat-card-subtitle>ourChecklists</mat-card-subtitle>
    </mat-card-header>
    <img
      mat-card-image
      src="../../assets/images/home-1-400x300.jpg"
      alt="ourChecklists"
      class="appLogoImg"
    />
    <mat-card-content>
      <p>Team oriented checklists management application</p>
      <p><b>Version: </b> 11.01</p>
      <p><b>Designer: </b> David Somerville</p>
      <p>
        <b>Contact: </b>
        <a href="mailto:david@lupincorp.com">david@lupincorp.com</a>
      </p>
      <p>
        <b>By Lupincorp: </b>
        <a href="https://lupincorp.com" target="_blank"
          >https://lupincorp.com<img
            class="lupinCorpLogo"
            src="../../assets/images/lupincorp.png"
            style="height: 24px; margin-left: 24px"
          />
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</div>
