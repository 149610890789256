<div class="dialog-heading">Select Activity</div>
<div class="activity-list">
  <div
    class="list-item"
    *ngFor="let activity of activities$ | async"
    [style.backgroundColor]="
      activity.id == selectedActivity?.id ? 'lightgrey' : 'white'
    "
    (click)="onActivitySelected(activity)"
    [hidden]="isHidden(activity)"
  >
    {{ activity.name }}
  </div>
</div>

<button
  mat-button
  matTooltip="Add Activity"
  (click)="returnActivity()"
  [disabled]="!selectedActivity"
  class="action-button"
>
  <mat-icon>check</mat-icon>OK
</button>

<button class="action-button" mat-button matTooltip="Cancel" mat-dialog-close>
  <mat-icon>close</mat-icon>Cancel
</button>
