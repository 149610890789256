<mat-card class="video-card">
  <div class="aspect-ratio" *ngIf="clickedVideo">
    <iframe
      height="300px"
      width="200px"
      [src]="safeVideoURL"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div class="aspect-ratio" *ngIf="!clickedVideo">
    <img [src]="safeImageURL" (click)="clickedVideo = true" />
  </div>
  <img
    class="youtubeplay"
    *ngIf="!clickedVideo"
    src="../../assets/images/youtubeplay.png"
    (click)="clickedVideo = true"
  />
</mat-card>
