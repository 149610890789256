<table mat-table [dataSource]="users$ | async" class="mat-elevation-z8">
  <ng-container matColumnDef="displayName" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let user">
      {{ user.displayName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email" sticky>
    <th mat-header-cell *matHeaderCellDef>eMail</th>
    <td mat-cell *matCellDef="let user">
      <a href="mailto:{{ user.email }}">{{ user.email }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="role" sticky>
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let user">
      {{ user.role }}
    </td>
  </ng-container>

  <ng-container matColumnDef="uid">
    <th mat-header-cell *matHeaderCellDef [hidden]="hideAddRemove"></th>
    <td
      mat-cell
      class="deleteRow"
      *matCellDef="let user"
      [hidden]="hideAddRemove"
    >
      <button
        mat-button
        matTooltip="Remove {{ user.displayName }} from team"
        (click)="
          openTeamUserRemoveDialog(
            user.uid,
            user.displayName,
            teamId,
            user.role
          )
        "
      >
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="addButton" sticky>
    <th mat-header-cell *matHeaderCellDef colspan="5" [hidden]="hideAddRemove">
      <button
        class="add-button"
        mat-button
        (click)="openTeamUserAddDialog(teamId)"
      >
        <mat-icon>add</mat-icon>
        Add new team member
      </button>
    </th>
  </ng-container>

  <ng-container *ngIf="!hideAddRemove">
    <tr mat-header-row *matHeaderRowDef="['addButton']" class="add-row"></tr>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
