<app-subheading
  prompt="Template Viewer"
  matIcon="dynamic_feed"
  [iconActions]="iconActions"
  (iconAction)="iconAction($event)"
></app-subheading>

<div class="page-container">
  <ng-container
    *ngIf="(user$ | async)?.isAdmin || (user$ | async)?.isTemplateManager"
  >
    <button
      mat-raised-button
      (click)="goToDesigner()"
      matTooltip="Warning, only do this if you know what you are doing."
    >
      Designer
    </button></ng-container
  >
  <div class="prompt">
    Name
  </div>
  <div>
    {{ checklist.name }}
  </div>

  <div class="prompt">Description</div>
  <div>{{ checklist.description }}</div>

  <div class="prompt">Extract</div>
  <div>
    <a *ngIf="downLoadReady" [href]="fileUrl" [download]="extractName"
      >Template as JSON
    </a>
  </div>

  <div class="prompt">
    Resources
  </div>
  <div>
    <app-resourcelistsimple
      [resources]="checklist.resources"
    ></app-resourcelistsimple>
  </div>

  <div class="prompt">Team</div>
  <div>{{ (checklist.team | doc | async)?.name }}</div>

  <div class="prompt">Category</div>
  <div>{{ (checklist.category | doc | async)?.name }}</div>

  <br />
  <div class="checklistitem-frame">
    <div>
      <span class="prompt">Template Items</span>
    </div>

    <table
      mat-table
      [dataSource]="checklistitems$ | async"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let checklistitem; let i = index">
          <div>{{ i + 1 }}. {{ checklistitem.name }}</div>

          <div>
            <span class="item-prompt">Description </span>
            <span class="item-detail">{{ checklistitem.description }}</span>
          </div>
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
