<table mat-table [dataSource]="activities$ | async" class="mat-elevation-z8">
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let activity">
      <a routerLink="/category/{{ categoryId }}/activity/{{ activity.id }}">
        {{ activity.name }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="description" sticky>
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let activity">
      {{
        activity.description.length < 100
          ? activity.description
          : activity.description.substring(0, 99) + "..."
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="deleteRow" *matCellDef="let activity">
      <button
        mat-button
        matTooltip="Delete {{ activity.name }} "
        (click)="onCategoryActivityDelete(activity.id)"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="addButton" sticky>
    <th mat-header-cell *matHeaderCellDef colspan="5">
      <span class="activities-heading"
        ><mat-icon>emoji_flags</mat-icon>Activities</span
      >
      <button class="add-button" mat-button (click)="onCategoryActivityAdd()">
        <mat-icon>add</mat-icon>
        Add new activity
      </button>
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['addButton']" class="add-row"></tr>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
