<table
  mat-table
  [dataSource]="checklistitems$ | async"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="name" sticky>
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let checklistitem; let i = index">
      <a
        [routerLink]="
          '/checklist/' +
          checklist.id +
          '/checklistitemedit/' +
          checklistitem.id
        "
        title="Item details..."
        >{{ i + 1 }}. {{ checklistitem.name }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="resultType" sticky>
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let checklistitem">
      <app-checklistitemresult
        [checklistitem]="checklistitem"
        [cid]="checklist.id"
        (change)="onResultChange($event, checklistitem)"
        [redirectForEvidence]="true"
      ></app-checklistitemresult>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
