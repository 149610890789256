<app-subheading
  [prompt]="
    'Checklist Item Design - ' +
    (crudAction == Crud.Create
      ? 'Create'
      : crudAction == Crud.Update
      ? 'Update'
      : 'Delete')
  "
  [iconActions]="[
    {
      icon: 'arrow_back',
      toolTip: 'Return to Checklist Design',
      routerLink: '/checklistdesign/' + cid
    }
  ]"
  matIcon="check_box"
></app-subheading>

<ng-container *ngIf="(checklist$ | async)?.isTemplate">
  <mat-expansion-panel [expanded]="false" class="light-highlight">
    <mat-expansion-panel-header class="highlight">
      <mat-panel-title>
        Template Item- Be Careful!
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>
      You are editing a Template Item. Only the sequence and resources can be
      updated.
    </p>
  </mat-expansion-panel>
</ng-container>

<div class="page-container">
  <form class="standard-form" [formGroup]="checklistitemForm">
    <fieldset [disabled]="crudAction == Crud.Delete">
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="name"
          (change)="onFieldUpdate('name')"
          placeholder="Name"
          [readonly]="(checklist$ | async)?.isTemplate"
          required
        />
        <mat-error
          *ngIf="checklistitemForm.controls['name'].hasError('required')"
        >
          Name is required.
        </mat-error>
        <mat-error
          *ngIf="checklistitemForm.controls['name'].hasError('minlength')"
        >
          Name must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="checklistitemForm.controls['name'].hasError('maxlength')"
        >
          Name must be no more than 160 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          formControlName="description"
          (change)="onFieldUpdate('description')"
          matAutosizeMinRows="3"
          matAutosizeMaxRows="10"
          matTextareaAutosize
          placeholder="Description"
          [readonly]="(checklist$ | async)?.isTemplate"
          required
        ></textarea>
        <mat-error
          *ngIf="checklistitemForm.controls['description'].hasError('required')"
        >
          Description is required.
        </mat-error>
        <mat-error
          *ngIf="
            checklistitemForm.controls['description'].hasError('minlength')
          "
        >
          Description must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="
            checklistitemForm.controls['description'].hasError('maxlength')
          "
        >
          Description must be no more than 5000 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <input
          matInput
          formControlName="sequence"
          (change)="onFieldUpdate('sequence', 'number')"
          placeholder="Sequence Number"
          required
        />
        <mat-error
          *ngIf="checklistitemForm.controls['sequence'].hasError('required')"
        >
          Sequence is required.
        </mat-error>
      </mat-form-field>
    </fieldset>

    <br />

    <ng-container *ngIf="!(checklist$ | async)?.isTemplate">
      <app-activitylistedit
        [activities]="checklistitem.activities"
        [category]="(checklist$ | async)?.category | doc | async"
        (change)="onActivitiesChange($event)"
      >
      </app-activitylistedit>
      <br />
    </ng-container>

    <app-resourcelistedit
      [resources]="checklistitem.resources"
      (change)="onResourcesChange($event)"
    ></app-resourcelistedit>
    <br />
    <mat-card>
      <h6>Checklist Result Options</h6>
      <mat-radio-group
        [(ngModel)]="checklistitem.resultType"
        [ngModelOptions]="{ standalone: true }"
        (change)="updateResultType()"
        [disabled]="
          crudAction == Crud.Delete || (checklist$ | async)?.isTemplate
        "
      >
        <mat-radio-button [value]="ChecklistitemResultType.checkbox">
          Checkbox
        </mat-radio-button>
        <mat-radio-button [value]="ChecklistitemResultType.rating">
          Rating
        </mat-radio-button>
      </mat-radio-group>
      <div>
        <mat-checkbox
          class="full-width"
          (change)="updateAllowNA()"
          [(ngModel)]="checklistitem.allowNA"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="
            crudAction == Crud.Delete || (checklist$ | async)?.isTemplate
          "
          >Allow users to enter NA ?</mat-checkbox
        >
      </div>
      <div>
        <mat-checkbox
          class="full-width"
          (change)="updateRequireEvidence()"
          [(ngModel)]="checklistitem.requireEvidence"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="
            crudAction == Crud.Delete || (checklist$ | async)?.isTemplate
          "
          >User must provide evidence?</mat-checkbox
        >
      </div>
    </mat-card>

    <br />
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Create"
      [disabled]="!checklistitemForm.valid"
      (click)="onCreate()"
      id="btnCreate"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Delete"
      (click)="onDelete()"
      id="btnDelete"
    >
      <mat-icon>clear</mat-icon>
      Delete
    </button>
  </form>
</div>
