<app-subheading prompt="Categories List" matIcon="category"></app-subheading>
<div class="page-container">
  <button
    mat-raised-button
    routerLink="/category/create"
    style="float: right; margin-bottom: 10px;"
  >
    <i class="material-icons-outlined">category</i>&nbsp;
    <span>Create a New Category</span>
  </button>
  <br />

  <table mat-table [dataSource]="categories$ | async" class="mat-elevation-z8">
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let category">
        <a routerLink="/category/{{ category.id }}">{{ category.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let category">
        {{
          category.description.length < 100
            ? category.description
            : category.description.substring(0, 99) + "..."
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="deleteRow" *matCellDef="let category">
        <button
          mat-button
          [routerLink]="['/category/delete/', category.id]"
          matTooltip="Delete Category"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
