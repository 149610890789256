<mat-spinner *ngIf="showSpinner" class="spinner_container"></mat-spinner>
<app-subheading prompt="User Profile" matIcon="person"></app-subheading>
<div class="page-container">
  <!-- Use an array of keyValuePairs to display most of the user properties -->
  <div class="gridwrapper">
    <div class="prompt">DisplayName</div>
    <div>{{ (user$ | async)?.displayName }}</div>
    <div class="prompt">UID</div>
    <div>{{ (user$ | async)?.uid }}</div>
    <div class="prompt">eMail</div>
    <div>{{ (user$ | async)?.email }}</div>
    <div class="prompt">Created</div>
    <div>
      {{ getDate((user$ | async)?.dateCreated) }}
    </div>
    <div class="prompt">Last Logon</div>
    <div>
      {{ getDate((user$ | async)?.dateLastLogon) }}
    </div>

    <div class="prompt">Photo</div>
    <div>
      <img class="photoURL" src="{{ (user$ | async)?.photoURL }}" />
      <!-- {{ (user$ | async)?.photoURL }} -->

      <div>
        <button
          class="add-button"
          mat-raised-button
          (click)="fileUpload.click()"
        >
          <mat-icon>add</mat-icon>Upload New Photo
        </button>
        <div class="fileUploadMsg">{{ fileUploadMsg }}</div>

        <input
          type="file"
          #fileUpload
          hidden="true"
          (change)="onUploadFile($event)"
          multiple="false"
          accept="image/*"
        />
      </div>
    </div>

    <div class="prompt">Is Administrator?</div>
    <div>
      <mat-checkbox
        #isAdmin
        [ngModel]="(user$ | async)?.isAdmin"
        (click)="updateField('isAdmin', !isAdmin.checked)"
        [disabled]="!fullAccess"
        matTooltip="Administrators have open access to all functions in the system, and can manage other users"
      ></mat-checkbox>
    </div>

    <div class="prompt">Is Activated?</div>
    <div>
      <mat-checkbox
        #isActivated
        [ngModel]="(user$ | async)?.isActivated"
        (click)="updateField('isActivated', !isActivated.checked)"
        [disabled]="!fullAccess"
        matTooltip="Users must be activated before any access is provided to the system"
      ></mat-checkbox>
    </div>

    <div class="prompt">Can Create Teams?</div>
    <div>
      <mat-checkbox
        #canCreateTeams
        [ngModel]="(user$ | async)?.canCreateTeams"
        (click)="updateField('canCreateTeams', !canCreateTeams.checked)"
        [disabled]="!fullAccess"
        matTooltip="User can create and manage teams"
      ></mat-checkbox>
    </div>

    <div class="prompt">Can Manage Categories?</div>
    <div>
      <mat-checkbox
        #isCategoryManager
        [ngModel]="(user$ | async)?.isCategoryManager"
        (click)="updateField('isCategoryManager', !isCategoryManager.checked)"
        [disabled]="!fullAccess"
        matTooltip="User can access and manage categories and related actions"
      ></mat-checkbox>
    </div>

    <div class="prompt">Can Manage Resources?</div>
    <div>
      <mat-checkbox
        #isResourceManager
        [ngModel]="(user$ | async)?.isResourceManager"
        (click)="updateField('isResourceManager', !isResourceManager.checked)"
        [disabled]="!fullAccess"
        matTooltip="User can create and manage resources"
      ></mat-checkbox>
    </div>

    <div class="prompt">Can Manage Templates?</div>
    <div>
      <mat-checkbox
        #isTemplateManager
        [ngModel]="(user$ | async)?.isTemplateManager"
        (click)="updateField('isTemplateManager', !isTemplateManager.checked)"
        [disabled]="!fullAccess"
        matTooltip="User can create and delete templates"
      ></mat-checkbox>
    </div>
  </div>
</div>
