<mat-sidenav-container>
  <!-- add this to the mat-sidebar tag to have menu auto close (click)="sidenav.toggle()" -->
  <mat-sidenav #sidenav (click)="sidenav.toggle()" class="no-print">
    <mat-nav-list>
      <a mat-list-item routerLink="/">
        <mat-icon>home</mat-icon>
        <span id="mainMenuHome">&nbsp;Home</span>
      </a>

      <span
        *ngIf="(auth.loggedIn$ | async) && (auth.user$ | async)?.isActivated"
      >
        <!-- My Checklists  -->

        <a mat-list-item routerLink="mychecklists">
          <mat-icon>playlist_add_check</mat-icon>
          <span id="mainMenuMyChecklists">&nbsp;My Checklists</span>
        </a>
        <!-- Checklist Search -->
        <a
          mat-list-item
          routerLink="checklistSearch"
          *ngIf="
            (auth.user$ | async)?.isAdmin ||
            isTeamManager(auth.user$ | async) ||
            isTeamReviewer(auth.user$ | async) ||
            isTeamMember(auth.user$ | async)
          "
        >
          <mat-icon>search</mat-icon>
          <span id="mainMenuChecklistSearch">&nbsp;Checklist&nbsp;Search</span>
        </a>

        <!-- Teams  -->
        <ng-container>
          <a
            mat-list-item
            routerLink="teams"
            *ngIf="
              (auth.user$ | async)?.isAdmin ||
              hasCrossTeamRole(auth.user$ | async)
            "
          >
            <mat-icon>supervisor_account</mat-icon>
            <span id="mainMenuTeams">&nbsp;My Teams</span>
          </a>
        </ng-container>

        <!-- Templates  -->

        <a mat-list-item routerLink="templates">
          <mat-icon>dynamic_feed</mat-icon>
          <span id="mainMenuTemplates">&nbsp;Templates</span>
        </a>

        <!-- DataAnalysis  -->

        <a
          mat-list-item
          routerLink="dataVisualization"
          *ngIf="
            (auth.user$ | async)?.isAdmin ||
            isTeamManager(auth.user$ | async) ||
            isTeamReviewer(auth.user$ | async)
          "
        >
          <mat-icon>insert_chart_outlined</mat-icon>
          <span id="mainMenuDataVisualization"
            >&nbsp;Data&nbsp;Visualizations</span
          >
        </a>

        <!-- Administrator Functions -->
        <mat-divider
          *ngIf="
            (auth.user$ | async)?.isAdmin ||
            (auth.user$ | async)?.isCategoryManager
          "
        ></mat-divider>
        <!-- Categories  -->
        <a
          mat-list-item
          routerLink="categories"
          *ngIf="
            (auth.user$ | async)?.isAdmin ||
            (auth.user$ | async)?.isCategoryManager
          "
        >
          <mat-icon>category</mat-icon>
          <span id="mainMenuCategories">&nbsp;Categories</span>
        </a>
        <!-- Resources  -->
        <a
          mat-list-item
          routerLink="resources"
          *ngIf="
            (auth.user$ | async)?.isAdmin ||
            (auth.user$ | async)?.isResourceManager
          "
        >
          <mat-icon>folder_open</mat-icon>
          <span id="mainMenuResources">&nbsp;Resources</span>
        </a>
        <!-- User Management -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="users"
        >
          <mat-icon>how_to_reg</mat-icon>
          <span id="mainMenuUsers">&nbsp;User Management</span>
        </a>

        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="adminUtils"
        >
          <mat-icon>construction</mat-icon>
          <span id="mainMenuAdminUtils">&nbsp;Admin. Utilities</span>
        </a>
      </span>
      <mat-divider></mat-divider>

      <a mat-list-item routerLink="about">
        <mat-icon>question_answer</mat-icon>
        <span id="mainMenuAbout">&nbsp;About</span>
      </a>

      <!-- Logged in and activated options -->
      <span
        *ngIf="(auth.loggedIn$ | async) && (auth.user$ | async)?.isActivated"
      >
        <!-- User functions , if we have too many then add a navigation page  -->
        <a
          mat-list-item
          [routerLink]="['myprofile/', (auth.user$ | async)?.uid || '']"
        >
          <mat-icon>person</mat-icon>
          <span id="mainMenuMyProfile">&nbsp;My Profile</span>
        </a>
      </span>

      <span *ngIf="auth.loggedIn$ | async">
        <a
          mat-list-item
          routerLink="logout"
          (click)="logout()"
          *ngIf="auth.loggedIn$ | async"
        >
          <mat-icon>exit_to_app</mat-icon>
          <span id="mainMenuLogout">&nbsp;Logout</span>
        </a>
      </span>

      <!-- Not logged in -->
      <span *ngIf="!(auth.loggedIn$ | async)">
        <mat-divider></mat-divider>
        <a mat-list-item routerLink="login">
          <mat-icon>account_circle</mat-icon>
          <span id="mainMenuLogin">&nbsp;Login</span>
        </a>
      </span>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar color="primary" class="no-print">
    <button
      mat-icon-button
      class="noOutline"
      (click)="sidenav.open('mouse')"
      id="mainMenu"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <div class="filler"></div>
    <div *ngIf="!isConnected" style="margin-right: 20px;">
      <mat-chip-list>
        <mat-chip
          class="offline-indicator"
          title="Application is offline and will have more limited functionality."
        >
          Offline
        </mat-chip>
      </mat-chip-list>
    </div>
    ourChecklists &nbsp; &nbsp;
    <ng-container *ngIf="auth.user$ | async as user">
      <img
        *ngIf="user.isActivated"
        id="mainToolBarIsActivated"
        class="user-avatar"
        [src]="user.photoURL"
        [matTooltip]="user.displayName"
      />
      <img
        *ngIf="user && !user.isActivated"
        id="mainToolBarNotActivated"
        class="user-avatar"
        src="../../assets/images/Block_user_pic.png"
        matTooltip="You are not activated, contact an administrator to have your account activated to use this system."
      />
    </ng-container>
  </mat-toolbar>
  <router-outlet></router-outlet>
</mat-sidenav-container>
